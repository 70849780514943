<template>
  <modal name="locale-chooser"
         height="auto"
         :adaptive="true"
         :scrollable="true"
         :draggable="false"
         :delay="100"
         @before-open="beforeOpen">

    <div slot="top-right">
      <a @click="$modal.hide('locale-chooser')" class="close-modal">
        <i class="fa fa-times-thin" aria-hidden="true"></i>
      </a>
    </div>

    <div class="panel locale-panel">
      <div class="panel-heading">
        <div class="row">
          <div class="col-xs-12">
            <h2>{{ t('js.locale_modal.currency_and_language') }}</h2>
          </div>
        </div>
      </div>
      <div class="panel-body">
        <div class="row">
          <form @submit="changeLocale">
            <div class="col-xs-10 col-xs-push-1 margin-bottom-sm">
              <v-select v-model="selectedCurrency" :options="currencies" :searchable="false">
                <template slot="selected-option" slot-scope="option">
                  <i :class="'flag flag-'+option.flag_name"></i>
                  <span class="locale-label">
                    {{ option.label }}
                  </span>
                </template>
                <template slot="option" slot-scope="option">
                  <i :class="'flag flag-'+option.flag_name"></i>
                  <span class="locale-label">
                    {{ option.label }}
                  </span>
                </template>
              </v-select>
            </div>
            <div class="col-xs-10 col-xs-push-1">
              <v-select v-model="selectedLanguage" :options="languages" :searchable="false">
                <template slot="selected-option" slot-scope="option">
                  {{ option.value|langName }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.value|langName }}
                </template>
              </v-select>
            </div>
            <div class="col-xs-10 col-xs-push-1 margin-top-short">
              <button v-cloak class="btn btn-cwc btn-primary full-width">
                {{ t('js.locale_modal.save') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  export default {
    name: 'LocaleModal',
    props: ['locale', 'language', 'currency', 'currencies', 'languages'],
    data: function() {
      return {
        selectedCurrency: '',
        selectedLanguage: ''
      }
    },
    methods: {
      beforeOpen(event) {
        this.selectedCurrency = this.currency;
        this.selectedLanguage = this.language;
      },
      t: function(k) {
        return window.I18n.t(k);
      },
      changeLocale: function(e) {
        e.preventDefault();
        console.log('Changing locale to '+this.selectedLanguage.value+'; '+this.selectedCurrency.value);

        var body = {'currency': this.selectedCurrency.value};

        this.$http.patch('/'+this.locale+'/currency/update', body).then(function() {
          window.location.href = '/'+this.selectedLanguage.value;
        });
        return false;
      }
    },
    filters: {
      langName(langcode) {
        switch(langcode) {
          case 'en':
            return 'English';
          case 'fr':
            return 'Française';
          case 'it':
            return 'Italiano';
          case 'es':
            return 'Español';
          case 'de':
            return 'Deutsch';
          default:
            return langcode.toUpperCase();
        }
      }
    }
  }
</script>

<style>
  .v--modal-top-right {
    right: 8px;
  }

  .v--modal-overlay {
    z-index: 1050;
  }

  .v--modal-overlay .v--modal-box {
    overflow: visible;
  }

  .close-modal {
    font: normal normal normal 50px/1 FontAwesome;
    width: 50px;
    height: 50px;
    margin-right: 15px;
    text-align: center;
    cursor: pointer;
    color: gray;
  }

  .fa-times-thin:before {
    content: '\00d7';
  }

  .locale-panel .panel-heading {
    text-align: center;
  }

  .locale-panel .panel-heading h2 {
    margin-bottom: 10px;
  }

  .locale-panel .btn-primary {
    margin-left: 0;
    margin-right: 0;
  }

  .locale-panel .dropdown-toggle {
    padding: 10px 15px;
  }

  .v-select .dropdown-toggle .clear {
    visibility: hidden;
  }

  .locale-panel .dropdown li a {
    font-size: 1.4em;
    padding: 10px 20px;
  }

  .locale-panel .dropdown .locale-label {
    padding-left: 15px;
  }

  .locale-panel .dropdown li a i {
    padding-right: 0.5em;
  }

  .dropdown.v-select {
    font-size: 1.4em;
  }

  .locale-panel .dropdown li a {
    margin-top: 5px;
    margin-bottom: 5px;
  }
</style>
