import Vue from 'vue/dist/vue.esm'

import VueResource from 'vue-resource'
Vue.use(VueResource);

import VModal from 'vue-js-modal'
Vue.use(VModal);

import vSelect from 'vue-select'
Vue.component('v-select', vSelect);

import LocaleModal from './components/locale_modal.vue'

document.addEventListener('DOMContentLoaded', () => {
  Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  var element = document.getElementById('locales');

  var currencies = JSON.parse(element.dataset.currencies);
  var languages = JSON.parse(element.dataset.languages);

  var currency = JSON.parse(element.dataset.currency);
  var language = JSON.parse(element.dataset.language);

  var locale = element.dataset.locale;

  if (element !== null) {
    new Vue({
      el: element,
      data: function() {
        return {
          language: language,
          currency: currency,
          currencies: currencies,
          languages: languages,
          locale: locale
        }
      },
      methods: {
        showLocales() {
          this.$modal.show('locale-chooser');
        }
      },
      components: {
        localeModal: LocaleModal
      },
      mounted: function() {}
    });
  }
});
